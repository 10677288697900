import { FunctionComponent, useEffect } from "react";
import { getNextUrl, restoreSession } from "lib/restore";
import { useTranslations } from "hooks/translations";
import { useConfig, useUpdateConfig } from "hooks/config";
import Loader from "components/loader";
import { useUpdateQuestionnaireState } from "hooks/state";
import { useUpdateQuestionnaire } from "hooks/questionnaire";
import {
  ControlQuestionnaireVersion,
  QuestionnaireVersion,
} from "models/questionnaire";
import { write } from "lib/storage";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getProgramView,
  getQuestionnaireUrl,
  getStaticUrl,
  getVersion,
  isFinished,
  isSupported,
} from "lib/questionnaire";
import {
  loadCleanQuestionnaire,
  loadQuestionnaireState,
  saveQuestionnaireState,
} from "services/questionnaire";
import { buildState, isStateValid } from "lib/state";
import { fetchEntitlement } from "services/entitlement";
import { isEntitlementActive, isEntitlementExpired } from "lib/entitlement";
import { StaticViewPathEnum } from "models/question";

// THIS REQUIRES BETTER LOGIC ==> CHECK THE VALUE IS A VALID VARIANT NUMBER
const parseTarget = (params: URLSearchParams) => {
  const tempTarget = params.get("target");
  if (tempTarget === null || tempTarget.length > 2) {
    return undefined;
  }
  return tempTarget as QuestionnaireVersion;
};

export const TARGET_KEY = "target";

const LinkLoginView: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const config = useConfig();
  const updateConfig = useUpdateConfig();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const t = useTranslations();
  const tempToken = searchParams.get("token") || undefined;
  const discount = searchParams.get("discount") || undefined;
  const navigate = useNavigate();

  // variant override mechanism
  const target = parseTarget(searchParams);

  useEffect(() => {
    const init = async () => {
      if (!tempToken) {
        return;
      }

      try {
        // restore session, modifies config possibly
        await restoreSession({ config, updateConfig, tempToken });
      } catch (err) {
        // store target to storate
        write(TARGET_KEY, target);

        // link expired
        navigate(t("/login/expired"));
        return;
      }

      // load existing state
      let restoredState = await loadQuestionnaireState(config);

      // if the restored state is invalid, reset the state
      if (isStateValid(restoredState) === false) {
        restoredState = buildState(config);
        restoredState.id = ControlQuestionnaireVersion;

        // mark signup done, so we won't go there again
        restoredState.signupDone = true;
      }

      // if we have target, use that on (resets to fresh state)
      if (target) {
        // change variant to target
        restoredState.id = target;

        // save change to server
        const tempQuestionnare = await loadCleanQuestionnaire(
          config.uuid,
          target,
          false,
        );
        await saveQuestionnaireState(tempQuestionnare, restoredState, true);
      }

      // if the restored state questionnaire is not supported, set to control
      if (isSupported(restoredState.id) === false) {
        restoredState = buildState(config);
        restoredState.id = ControlQuestionnaireVersion;

        // mark signup done, so we won't go there again
        restoredState.signupDone = true;
      }

      // check entitlement
      const entitlement = await fetchEntitlement();
      if (entitlement && isEntitlementActive(entitlement)) {
        const url = t("/manage-your_plan");
        return navigate(url);
      } else if (entitlement && isEntitlementExpired(entitlement)) {
        let url = getStaticUrl(
          t,
          restoredState.id,
          StaticViewPathEnum.REACTIVATE,
        );
        // pipe discount attribute to reactivate view
        if (discount) {
          url += `?discount=${discount}`;
        }
        return navigate(url);
      }

      // restored state is valid, fetch questionnaire
      const restoredQuestionnaire = await loadCleanQuestionnaire(
        config.uuid,
        restoredState.id,
        true,
      );
      const version = getVersion(restoredQuestionnaire);

      // update state
      updateQuestionnaire(restoredQuestionnaire);
      updateQuestionnaireState(restoredState);

      // if the restored state is "finished"
      if (isFinished(restoredState)) {
        const programView = getProgramView(
          restoredQuestionnaire,
          restoredState,
        );
        navigate(getQuestionnaireUrl(t, version, programView.id));
        return;
      }

      // take the user to the correct screen
      const url = getNextUrl(restoredQuestionnaire, restoredState, t, version);
      navigate(url);
    };
    init();
  }, [tempToken]);

  return <Loader />;
};

export default LinkLoginView;
